import React, { Fragment, Suspense, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter } from 'react-router-dom';
import RecoilNexus from 'recoil-nexus';
import { RecoilRoot } from 'recoil';

import Loader from './components/loader';

import useDevice from './hooks/useDevice';
import useWindowResize from './hooks/useWindowResize';

import HookHydrate from './hydrators/hookHydrate';
import StateHydrate from './hydrators/stateHydrate';
import WhiteLabelHydrate from './hydrators/whiteLabelHydrate';

import { isWhiteLabel, subDomain } from './helpers';

import lazyWithRetry from './utils/lazyWithRetry';

import './assets/scss/skeleton.scss';

const Layout = lazyWithRetry(() => import('./containers/layout').then());
const Splash = lazyWithRetry(() => import('./components/splash').then());
const ErrorFallback = lazyWithRetry(() => import('./components/errorFallback').then());

const kit = subDomain === 'kit';

const App = () => {
    const {
        screenHeight,
    } = useWindowResize();

    const {
        isMobile,
    } = useDevice();

    useEffect(() => {
        document.documentElement.style.setProperty('--vh', screenHeight + 'px');
    }, [screenHeight, isMobile]);

    useEffect(() => {
        document.documentElement.style.setProperty('--vih', window.innerHeight + 'px');
    }, [isMobile]);

    useEffect(() => {
        document.documentElement.style.setProperty('--screen-height', window.screen.height + 'px');
    }, [isMobile]);

    const ConditionalWhiteLabelHydrate = isWhiteLabel ? WhiteLabelHydrate : Fragment;

    const loader = (
        <Loader absolute />
    );

    return (
        <BrowserRouter>
            <RecoilRoot>
                <RecoilNexus />
                <ErrorBoundary
                    fallback={(
                        <Suspense fallback={loader}>
                            <ErrorFallback />
                        </Suspense>
                    )}
                >
                    <ConditionalWhiteLabelHydrate>
                        <StateHydrate>
                            <HookHydrate>
                                <Suspense fallback={loader}>
                                    {kit ? (
                                        <Suspense fallback={loader}>
                                            <Splash />
                                        </Suspense>
                                    ) : (
                                        <Suspense fallback={loader}>
                                            <Layout />
                                        </Suspense>
                                    )}
                                </Suspense>
                            </HookHydrate>
                        </StateHydrate>
                    </ConditionalWhiteLabelHydrate>
                </ErrorBoundary>
            </RecoilRoot>
        </BrowserRouter>
    );
};

export default App;
