import translate from '../../utils/translate';

import whiteLabel from '../../constants/whiteLabel';

export const authenticationRoutes = {
    signIn: '/sign-in',
    signInVerifyAccount: '/sign-in/verify',
    signUp: '/sign-up',
    recoverPassword: '/recover-password',
    signUpVerifyEmail: '/sign-up/verify-email',
    signUpWelcome: '/sign-up/welcome',
    recoverPasswordCheckEmail: '/recover-password/check-email',
    resetPassword: '/reset-password/:token',
    secureYourAccount: '/secure-your-account',
    secureYourAccountSetup: '/secure-your-account/setup',
    secureYourAccountVerify: '/secure-your-account/verify',
    secureYourAccountDone: '/secure-your-account/done',
};

export const authenticationFields = {
    name: {
        id: 'name',
        placeholder: translate('Name'),
        autoComplete: 'name',
    },
    email: {
        id: 'email',
        type: 'email',
        placeholder: translate('Email'),
        autoComplete: 'email',
    },
    password: {
        id: 'password',
        type: 'password',
        placeholder: translate('Password'),
    },
    confirmPassword: {
        id: 'confirmPassword',
        type: 'password',
        placeholder: translate('Confirm Password'),
    },
};

export const authenticationData = {
    [authenticationRoutes.signIn]: {
        name: whiteLabel?.name ? (
            translate('Welcome to _NAME_', { _NAME_: whiteLabel?.name })
        ) : (
            translate('Welcome to Advelit')
        ),
        subTitle: translate('Sign in to your account'),
        fields: [
            authenticationFields.email.id,
            authenticationFields.password.id,
        ],
        noValidateFields: [
            authenticationFields.password.id,
        ],
        cta: {
            name: translate('Sign In'),
            type: 'submit'
        },
        suggest: {
            text: translate('Do not have an account yet?'),
            to: authenticationRoutes.signUp,
        },
        meta: {
            description: whiteLabel?.name ? (
                translate(`Welcome back to _NAME_! Sign in to create a campaign and get unlimited access to the new features that we have for you! Your next campaign awaits!`, { _NAME_: whiteLabel?.name })
            ) : (
                translate(`Welcome back to Advelit! Sign in to create a campaign and get unlimited access to the new features that we have for you! Your next campaign awaits!`)
            ),
        },
    },
    [authenticationRoutes.signUp]: {
        name: translate('Create an Account'),
        subTitle: translate('To create a new account please fill the fields'),
        fields: [
            authenticationFields.email.id,
            authenticationFields.name.id,
            authenticationFields.password.id,
        ],
        cta: {
            name: translate('Sign Up'),
        },
        suggest: {
            text: translate('Already a member?'),
            to: authenticationRoutes.signIn,
        },
        meta: {
            description: whiteLabel?.name ? (
                translate('Create your free _NAME_ account to get unlimited access to hundreds of screens waiting for your next great campaign!', { _NAME_: whiteLabel?.name })
            ) : (
                translate('Create your free Advelit account to get unlimited access to hundreds of screens waiting for your next great campaign!')
            ),
        },
    },
    [authenticationRoutes.recoverPassword]: {
        name: translate('Recover Password'),
        subTitle: translate('Enter your email'),
        fields: [
            authenticationFields.email.id,
        ],
        cta: {
            name: translate('Email me recovery link'),
        },
        suggest: {
            text: translate('Remembered your password?'),
            to: authenticationRoutes.signIn,
        },
        meta: {
            description: translate(`Forgot your password? - Happens to the best of us. Fill out your email, and we'll recover your account. Make sure to provide the correct email. Be careful next time!`),
        },
    },
    [authenticationRoutes.resetPassword]: {
        name: translate('Reset Your Password'),
        subTitle: translate('Strong passwords include numbers and letters'),
        fields: [
            authenticationFields.password.id,
            authenticationFields.confirmPassword.id,
        ],
        cta: {
            name: translate('Set new password'),
        },
        suggest: {
            text: translate('Remembered your password?'),
            to: authenticationRoutes.signIn,
        },
    },
    [authenticationRoutes.signUpWelcome]: {
        image: 'Hello',
        name: whiteLabel?.name ? (
            translate('Welcome to _NAME_', { _NAME_: whiteLabel?.name })
        ) : (
            translate('Welcome to Advelit')
        ),
        description: () => whiteLabel?.name ? ([
            translate('Thank you! Your account is verified. Now you have full access to _NAME_, you can start exploring our platform right now, What are you waiting for?', { _NAME_: whiteLabel?.name }),
        ]) : ([
            translate('Thank you! Your account is verified. Now you have full access to Advelit, you can start exploring our platform right now, What are you waiting for?'),
        ]),
        cta: {
            name: translate('Sign In'),
        },
    },
    [authenticationRoutes.signUpVerifyEmail]: {
        image: 'EmailSent',
        name: translate('Check Your Email'),
        description: email => [
            translate('In the century of robots and spammers, we kindly asking you to verify your account, we have sent an email to _EMAIL_ with verification link.', { '_EMAIL_': email }),
            translate('If you do not see the email, check other places it might be, like your junk, spam, social, or other folders.'),
        ],
        cta: {
            name: translate('Resend Again'),
            color: 'accent',
        },
    },
    [authenticationRoutes.recoverPasswordCheckEmail]: {
        image: 'EmailSent',
        name: translate('Check Your Email'),
        description: email => [
            translate('We have sent an email to _EMAIL_. Click the link in the email to reset your password.', { '_EMAIL_': email }),
            translate('If you do not see the email, check other places it might be, like your junk, spam, social, or other folders.'),
        ],
        cta: {
            name: translate('Resend Again'),
            color: 'accent',
        },
    },
    [authenticationRoutes.secureYourAccount]: {
        flowIn2FA: true,
        image: 'LockCheck',
        name: translate('Secure Your Account'),
        subTitle: translate('Turn on 2-Step Verification'),
        description: () => [
            translate('Enable 2-Step Verification to add an extra layer of security, making it harder for unauthorized access to your account.'),
        ],
    },
    [authenticationRoutes.secureYourAccountSetup]: {
        flowIn2FA: true,
        image: 'ScanQR',
        name: translate('2-Step Verification'),
        subTitle: translate('Please follow the instructions below'),
        description: () => [
            translate('Scan the QR with your authenticator app, or add the key manually to generate verification codes.'),
        ],
    },
    [authenticationRoutes.secureYourAccountVerify]: {
        flowIn2FA: true,
        image: 'ShieldCode',
        name: translate('Verify the Code'),
        description: () => [
            translate('Please fill in the verification code from the authenticator app to complete the setup'),
        ],
        cta: {
            name: translate('Verify & Activate'),
        },
    },
    [authenticationRoutes.signInVerifyAccount]: {
        flowIn2FA: true,
        image: 'ShieldCode',
        name: translate('Verify the Account'),
        description: () => [
            translate('Please fill in the verification code from the authenticator app to sign in'),
        ],
        cta: {
            name: translate('Verify & Sign In'),
        },
    },
    [authenticationRoutes.secureYourAccountDone]: {
        flowIn2FA: true,
        image: 'Fingerprint',
        name: translate('Backup Codes'),
        subTitle: translate('Save these codes now!'),
        description: () => [
            translate('If you ever lose access to your authenticator app you can use one of these codes to get access to your account'),
        ],
        cta: {
            name: translate('Done'),
        },
    },
};
